



























































































































































import Vue from 'vue';
import { required, email, minLength, sameAs, maxLength } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import ValidatedInput from '@/components/forms/ValidatedInput.vue';
import RegistrationService from '@/services/users/RegistrationService';
import PasswordComplexityValidator from '@/services/validators/PasswordComplexityValidator';
import ToastMessage from '@/models/ToastMessage';
import ValidatedCheckbox from '@/components/forms/ValidatedCheckbox.vue';
import Notification from '@/components/shared/Notification.vue';
import { MetaInfo } from 'vue-meta';

const validations = {
  user: {
    email: {
      required,
      minLength: minLength(5),
      maxLength: maxLength(50),
      email,
    },
    displayname: {
      minLength: minLength(5),
      maxLength: maxLength(50),
      required,
    },
    privacyCheck: {
      checked: (val: boolean) => val || false,
    },
    businessTerms: {
      checked: (val: boolean) => val || false,
    },
    allowEmailForPersonalOffersAt: {},
    allowEmailForFeedbackAt: {},
    password: {
      minLength: minLength(12),
      maxLength: maxLength(50),
      required,
      PasswordComplexityValidator,
    },
    passwordConfirmation: {
      sameAsPassword: sameAs('password'),
      required,
    },
  },
};

export default Vue.extend({
  mixins: [validationMixin],
  validations: validations,
  components: { ValidatedInput, ValidatedCheckbox, Notification },
  data() {
    return {
      user: {
        displayname: '',
        email: '',
        password: '',
        passwordConfirmation: '',
        privacyCheck: false,
        businessTerms: false,
        allowEmailForPersonalOffersAt: false,
        allowEmailForFeedbackAt: false,
      },
      loading: false,
      successful: false,
      message: '',
      showNotification: false,
    };
  },
  mounted() {
    this.setBreadCrumb();
  },
  metaInfo(): MetaInfo {
    return { title: this.$t('pages.auth.registerCustomer.meta.title').toString() };
  },
  computed: {
    hostRegisterLink(): string {
      let props = this.$router.resolve({ name: 'RegisterOwner' });
      return props.href;
    },
    checkPrivacyChecked(): boolean {
      return this.user.privacyCheck && this.user.businessTerms && !this.$v.$invalid;
    },
    hostLink(): string {
      return this.$t('pages.auth.registerCustomer.linkForHost', { link: this.hostRegisterLink }).toString();
    },
    maintenanceMode(): boolean {
      return process.env.VUE_APP_MAINTENANCE_MODE == 'true';
    },
  },
  methods: {
    setBreadCrumb() {
      this.$store.commit('UiStoreModule/setBreadcrumbItems', [
        { translationKey: 'pages.auth.registerCustomer.breadcrumb.last' },
      ]);
    },
    handleSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.message = this.$t('forms.common.error_message').toString();
        this.$store.commit(
          'addToastMessage',
          new ToastMessage(this.$t('forms.common.error_message').toString(), 'bg-warning')
        );
      } else {
        this.loading = true;
        RegistrationService.registerCustomer({ ...this.user }).then(
          () => {
            this.loading = false;
            this.showNotification = true;
          },
          (error) => {
            this.loading = false;
            this.message = error;
          }
        );
      }
    },

    handleCloseNotification() {
      this.$router.push('/auth/login');
    },

    checkIfValid(fieldName: string) {
      const field = this.$v.user[fieldName];
      if (field === undefined || !field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === '');
    },
  },
});
